import {ExamSession} from "./examSession";
import { Exam } from "./exam";

export class Preregistration {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    phoneNumber: string | null;
    session: ExamSession;
    exam: Exam | null = null;
    convocationSentAt: Date | null = null;
    convocationIsSending: boolean = false;

    constructor (data: any) {
        if (data) {
            this.id = data.id;
            this.firstname = data.first_name;
            this.lastname = data.last_name;
            this.email = data.email;
            this.session = new ExamSession(data.session);
            
            if (data.phone_number) {
                this.phoneNumber = data.phone_number;
            }

            if (data.exam) {
                this.exam = new Exam(data.exam);

            }         

            if (data.convocation_sent_at) {
                this.convocationSentAt = new Date(data.convocation_sent_at);
            }

        }
    }
}

